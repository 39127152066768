<template>
  <div class="bg-white p-20 rounded">
    <div class="mb-20">
      <a-radio-group v-model="status">
        <a-radio-button :value="2">所有子账号</a-radio-button>
        <a-radio-button :value="1">待通过</a-radio-button>
        <a-radio-button :value="-1">已拒绝</a-radio-button>
      </a-radio-group>
    </div>
    <div>
      <a-table
        :pagination="false"
        rowKey="id"
        :dataSource="dataSource"
        :columns="columns"
      >
        <span slot="index" slot-scope="text, record, index">{{
          index + 1 + limit * (current - 1)
        }}</span>
        <a-space slot="action" slot-scope="text, record">
          <a-button
            type="primary"
            ghost
            v-if="text === 2"
            @click="doUnlink(record.id)"
            >取消关联</a-button
          >
          <a-button
            type="primary"
            ghost
            v-if="text === 1"
            @click="doConfirmLink(record.id, true)"
            >通过</a-button
          >
          <a-button
            type="primary"
            ghost
            v-if="text === 1"
            @click="doConfirmLink(record.id, false)"
            >不通过</a-button
          >
          <a-button type="link" v-if="text === -1">已拒绝</a-button>
        </a-space>
      </a-table>
      <div class="text-center mt-20">
        <a-pagination
          :pageSize="limit"
          :total="total"
          v-model="current"
          @change="change"
        ></a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getLink, unlink, confirmLink } from "@/api/link";
export default {
  data() {
    return {
      status: 2,
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "index" },
        },
        {
          dataIndex: "name",
          title: "姓名",
          key: "",
        },
        {
          dataIndex: "department",
          title: "部门",
          key: "",
        },
        {
          dataIndex: "position",
          title: "职位",
          key: "",
        },
        {
          dataIndex: "phone",
          title: "手机号码",
          key: "",
        },
        {
          dataIndex: "email",
          title: "邮箱地址",
          key: "",
        },
        {
          dataIndex: "status",
          title: "操作",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      total: 0,
      current: 1,
      limit: 10,
    };
  },
  computed: {
    company() {
      return this.$store.state.userInfo.id;
    },
  },
  methods: {
    change(e) {
      this.current = e;
      const temp = {
        status: this.status,
        limit: this.limit,
        offset: (this.current - 1) * this.limit,
      };
      this.getLink(this.company, temp);
    },
    doUnlink(id) {
      this.unlink(this.company, id);
    },
    doConfirmLink(id, verified) {
      this.confirmLink(this.company, id, { verified });
    },
    getLink(id, params) {
      getLink(id, params)
        .then(({ data }) => {
          const { count, rows } = data;
          this.total = count;
          const temp = rows || [];
          this.dataSource = temp.map((item) => {
            const info = item.Info || {};
            const temp = {
              name: info.Name,
              department: info.Department,
              position: info.Position,
              phone: item.Phone,
              email: item.Email,
              status: item.LinkStatus,
              id: item.ID,
            };
            return temp;
          });
        })
        .catch(() => {
          this.$message.error("获取关联列表失败");
        });
    },
    unlink(company, user) {
      unlink(company, user)
        .then(() => {
          this.$message.success("取消关联成功");
          this.change(this.current);
        })
        .catch(() => {
          this.$message.error("解除关联失败");
        });
    },
    confirmLink(company, user, data) {
      confirmLink(company, user, data)
        .then(() => {
          this.$message.success("操作成功");
          this.change(this.current);
        })
        .catch(() => {
          this.$message.error("确认关联失败");
        });
    },
  },
  watch: {
    status: {
      handler() {
        this.change(1);
      },
      immediate: true,
    },
  },
};
</script>